body {
  background: #0A0A0A;
  color: #FFFFFF;
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: #0A0A0A;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-left .logo {
  width: 50px;
  margin-right: 20px;
}

.navbar-menu a {
  color: #FFFFFF;
  margin: 0 20px;
  text-decoration: none;
  position: relative;
  font-weight: 500;
  font-size: 1rem;
}

.navbar-menu a:hover::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  height: 2px;
  background: #00ACE6;
}

.main-wallet-btn {
  background: #002e4f;
  padding: 10px 20px;
  border-radius: 20px;
  color: #FFFFFF;
  font-weight: 500;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0,150,255,0.5);
}

.main-wallet-btn:hover {
  background: #004d75;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 40px;
  gap: 40px;
}

.left-panel {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card-balance {
  background: linear-gradient(45deg, #1dd1a1, #00ace6);
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
  position: relative;
  overflow: hidden;
}

.card-balance h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.coin-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.balance-label {
  color: #FFFFFF;
  margin-top: 10px;
  font-size: 0.9rem;
}

.balance-amount {
  margin-top: 5px;
  font-size: 2rem;
  font-weight: 700;
  color: #FFFFFF;
}

.action-buttons {
  display: flex;
  gap: 20px;
}

.action-buttons button {
  background: #1C1C1C;
  color: #fff;
  border: none;
  padding: 15px 25px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: background 0.3s;
}

.action-buttons button:hover {
  background: #333;
}

.transactions-panel {
  flex: 1;
  background: #111111;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
}

.transactions-panel h4 {
  margin-top: 0;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.transaction-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.transaction-item span {
  color: #ccc;
  font-size: 0.9rem;
}

body::before,
body::after {
  content: '';
  position: fixed;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -1;
}

body::before {
  background: #00ace6;
  top: 20%;
  left: 10%;
}

body::after {
  background: #1dd1a1;
  bottom: 10%;
  right: 15%;
}

.card-container {
  width: 400px;
  margin-top: 20px;
  position: relative;
}

.card-display {
  width: 100%;
  height: 220px;
  background: linear-gradient(45deg, #666, #aaa);
  border-radius: 20px;
  padding: 20px;
  color: #fff;
  position: relative;
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-logo {
  position: absolute;
  top: 20px;
  right: 20px;
}

.card-number {
  font-size: 1.4rem;
  letter-spacing: 2px;
  margin: 60px 0 20px;
  white-space: nowrap;
}

.card-info {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
}

.card-info .label {
  font-size: 0.7rem;
  color: #ddd;
}

.card-info .value {
  font-size: 1rem;
  color: #fff;
}

.user-card-container {
  display: flex;
  justify-content: center;
}

.user-card-display {
  width: 300px;
  height: 180px;
  background: linear-gradient(45deg, #666, #aaa);
  border-radius: 20px;
  color: #fff;
  position: relative;
  box-shadow: 0 0 20px rgba(0,0,0,0.3);

  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  text-align: center;
  padding: 20px;
}

.user-card-logo {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.user-card-number {
  font-size: 1.4rem;
  letter-spacing: 2px;
  margin: 20px 0;
  white-space: nowrap;
}

.user-card-info {
  display: flex;
  justify-content: center;
  gap: 30px;
  font-size: 0.9rem;
}

.user-card-info .label {
  font-size: 0.7rem;
  color: #ddd;
}

.user-card-info .value {
  font-size: 1rem;
  color: #fff;
}

.user-card-toggle {
  margin-top: 10px;
  background: #333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}
.user-card-toggle:hover {
  background: #444;
}
